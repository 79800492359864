import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { NavController, Platform } from '@ionic/angular';
import { IDEAStorageService, IDEATranslationsService } from '@idea-ionic/common';

import { AppService } from './app.service';

import { ServiceLanguages } from '@models/serviceLanguages.enum';

export const initGuard: CanActivateFn = async (): Promise<boolean> => {
  const platform = inject(Platform);
  const titleService = inject(Title);
  const metaService = inject(Meta);
  const navCtrl = inject(NavController);
  const storage = inject(IDEAStorageService);
  const t = inject(IDEATranslationsService);
  const app = inject(AppService);

  if (app.initReady) return true;

  await platform.ready();
  await storage.ready();

  await t.init(Object.values(ServiceLanguages), ServiceLanguages.English);

  titleService.setTitle(t._('COMMON.APP_NAME'));
  metaService.updateTag({ name: 'description', content: t._('COMMON.APP_DESCRIPTION') });

  // if an admin has just signed-in (see auth.guard), redirect to the admin main page
  if (app.user?.isAdmin()) {
    const desiredRedirectByAdmin = await storage.get('redirectToAdminPanelIfCompletesSignIn');
    if (desiredRedirectByAdmin) {
      await storage.remove('redirectToAdminPanelIfCompletesSignIn');
      navCtrl.navigateRoot(['admin', 'promotions']);
    }
  }

  app.initReady = true;

  return true;
};
