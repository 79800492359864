import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Platform, NavController } from '@ionic/angular';
import { CognitoUser as User } from 'idea-toolbox';
import { IDEAApiService, IDEAStorageService } from '@idea-ionic/common';
import { IDEAAuthService } from '@idea-ionic/auth';

import { AppService } from './app.service';

export const authGuard: CanActivateFn = async (): Promise<boolean> => {
  const platform = inject(Platform);
  const navCtrl = inject(NavController);
  const storage = inject(IDEAStorageService);
  const api = inject(IDEAApiService);
  const auth = inject(IDEAAuthService);
  const app = inject(AppService);

  if (app.authReady) return true;

  //
  // HELPERS
  //

  const doAuth = async (): Promise<void> => {
    const authRes = await auth.isAuthenticated(false, freshIdToken => (api.authToken = freshIdToken));
    api.authToken = authRes.idToken;
    app.user = new User(authRes.userDetails);
  };

  const navigateAndResolve = (navigationPath?: string[]): boolean => {
    if (navigationPath) navCtrl.navigateRoot(navigationPath);
    app.authReady = true;
    return true;
  };

  //
  // MAIN
  //

  if (app.authReady) return true;

  await platform.ready();
  await storage.ready();

  try {
    await doAuth();
    platform.resume.subscribe((): Promise<void> => doAuth());

    if (!app.user.isAdmin()) throw new Error('Unauthorised');

    const isModuleBasePath = !window.location.pathname.split('/')[2]?.length;
    if (isModuleBasePath) navigateAndResolve(['admin', 'promotions']);
    else navigateAndResolve(window.location.pathname.split('/'));
  } catch (err) {
    // if an admin wanted to reach the admin panel but wasn't signed-in, take note;
    // after the sign-in is completed, the init.guard will take them to the admin panel
    await storage.set('redirectToAdminPanelIfCompletesSignIn', true);
    navigateAndResolve(['auth']);
  }
};
