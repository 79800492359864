import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { initGuard } from './init.guard';
import { authGuard } from './auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'user', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: (): Promise<any> => import('@idea-ionic/auth').then(m => m.IDEAAuthModule),
    canActivate: [initGuard]
  },
  {
    path: 'user',
    loadChildren: (): Promise<any> => import('./user/user-routing.module').then(m => m.UserRoutingModule),
    canActivate: [initGuard]
  },
  {
    path: 'admin',
    loadChildren: (): Promise<any> => import('./admin/admin-routing.module').then(m => m.AdminRoutingModule),
    canActivate: [initGuard, authGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
