/**
 * Variables to configure an ITER IDEA's cloud app, together with its inner modules.
 */
export const environment = {
  idea: {
    project: 'dott',
    app: {
      version: '2.6.2',
      bundle: 'com.iteridea.dott',
      appleStoreURL: '',
      googleStoreURL: '',
      url: 'https://dottpartners.com',
      privacyPolicyURL: 'https://ridedott.com/privacy-policy',
      homepageURL: 'https://ridedott.com'
    },
    api: {
      url: 'api.dottpartners.com',
      stage: 'prod'
    },
    auth: {
      title: 'Dott promo codes',
      hasIntroPage: false,
      registrationIsPossible: false,
      singleSimultaneousSession: false,
      forceLoginWithMFA: false,
      // note: the passwordPolicy should be set matching the configuration of the Cognito User Pool
      passwordPolicy: {
        minLength: 8,
        requireLowercase: false,
        requireDigits: false,
        requireSymbols: false,
        requireUppercase: false
      }
    },
    ionicExtraModules: ['variables', 'common', 'auth'],
    website: 'https://iter-idea.com'
  },
  aws: {
    cognito: {
      userPoolId: 'eu-west-1_S6Pzua1Oo',
      userPoolClientId: '4lgh4nctds9vrsq7mto8r8n5u1'
    }
  },
  google: {
    recaptchaKey: '6LebdpMcAAAAAGjEsCVyFng0TPDFszuk84h3O_VB'
  }
};
